import './App.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Mint from './components/Mint.jsx'


function App() {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Container fluid className="container1">
        <Row>
          <Col className='text-md-start text-center mt-4 ms-md-5 pt-5 ps-md-5'>
            <img src="/images/logo2.png" className="img-fluid logo" />
          </Col>
          <Col className='text-md-end text-center me-5 mt-2 pt-5 pe-5 d-none d-md-block'>
            <a href="https://twitter.com/search?q=%23SteezyApeGang" target="_blank"><img alt="#SteezyApeGang" src="/images/steezyapegang.png" className='p-2 float-right steezy-ape-gang-hash-tag' /></a>
            <a href="https://twitter.com/okinagroup" target="_blank"><img alt="Twitter" src="/images/twitter.png" className='p-2 float-right social-icon'  /></a>
            <a href="https://opensea.io/collection/steezyapegang" target="_blank"><img alt="OpenSea" src="/images/opensea.png" className='p-2 float-right social-icon'  /></a>
          </Col>
        </Row>
      </Container>
      <Container fluid className="container2">
        <Row>
          <Col className='text-center pt-4 pb-5 pe-4 d-md-none d-block'>
            <a href="https://twitter.com/search?q=%23SteezyApeGang" target="_blank"><img alt="#SteezyApeGang" src="/images/steezyapegang.png" className='p-2 float-right steezy-ape-gang-hash-tag' /></a>
            <a href="https://twitter.com/okinagroup" target="_blank"><img alt="Twitter" src="/images/twitter.png" className='p-2 float-right social-icon'  /></a>
            <a href="https://opensea.io/collection/steezyapegang" target="_blank"><img alt="OpenSea" src="/images/opensea.png" className='p-2 float-right social-icon'  /></a>
          </Col>
        </Row>
        {/* <Row>
          <Col className='text-center text-light my-5'>
            <h4>Exclusive access to projects, merch, products, events, business solutions and more.</h4>
          </Col>
        </Row> */}
        <Row className='mx-md-5 px-md-5 mt-md-5 mb-5' x>
          <Col className='text-center px-5 '>
            <div className="text-light mb-5 pb-md-5">
              <h5>OKINAlabs is the leading biochemistry research facility, and is always developing new cutting-edge therapies.</h5>
            </div>
            <img alt="Claim Header" src="/images/claim.png" className='img-fluid pb-5 claimHeader' /> <br />
            <h6 className="text-light">Alpha21 Capsule claim is closed  <br /><br />
            <a class="text-white" href="https://opensea.io/assets/ethereum/0xef6da0ae231d95cb6097312e13f5fed0ad13bc4c/1">Shop the secondary market on OpenSea</a></h6>
            <h3 className='pt-4 text-light'></h3>
            {/* <Mint /> */}
          </Col>
        </Row>
        <Row className="pb-5">
          <Col className="pt-md-5 footer text-center">
          <img alt="Okina Logo Small" src="/images/logo-small.png" className='pe-5' />
            <img alt="Bryan Vee Signature" class="signature" src="/images/bryan-vee-sig.png" />  <br />
           <h6> Okina Creative Co. Steezy Ape Gang 2022 All rights reserved</h6>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
}

export default App;
