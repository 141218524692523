import axios from "axios";
import { MetaMaskInpageProvider } from "@metamask/providers";
import { BehaviorSubject } from 'rxjs';
import appConfig from "../app-config";

declare global {
  interface Window{
    ethereum?:MetaMaskInpageProvider
  }
}
class OkinaPassService {

    currentAccount = new BehaviorSubject("");

    async getMerkleProofs(address: string) {
        const response = await axios.get(`${appConfig.api}/api/GetMerkleProof?address=${address}`);
        return response.data;
    }
}

export default new OkinaPassService();